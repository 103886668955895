<template>
    <q-layout view="lHh Lpr lFf" class="shadow-2 rounded-borders">
      <q-page-container>
        <router-view />
      </q-page-container>
      <q-header reveal elevated>
        <q-toolbar>
          <!-- <q-btn flat round dense icon="menu" @click="drawerRight = !drawerRight" /> !-->

          <q-toolbar-title>
            <img style="max-width:400px;" src="./assets/Helland.jpg">
          </q-toolbar-title>

        </q-toolbar>
      </q-header>

      <q-footer reveal elevated>
        <q-toolbar>
          <!-- <q-btn flat round dense icon="menu" @click="drawerLeft = !drawerLeft" /> !-->

        </q-toolbar>
      </q-footer>
      
    </q-layout>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
img {
  max-width: 100%;
  height: auto;
}
</style>