<script setup>
  import { useRoute} from 'vue-router'
  import { onMounted , ref, watch } from 'vue'
  //import axios from 'axios'

  const route = useRoute()

  const gtin = ref(route.query.gtin)
  const serial = ref(route.query.serial)

  const site = ref(route.name)

  const name = ref("")
  const gmn = ref("")
  const link = ref("")

  const items = 
    [
      {
        "Gtin": "7090053440369",
        "Values":
        [
          {
            "Serial": "10001",
            "Rfid": "300EA4290200090000002711",
            "Name": "Helland Pan High Recliner",
            "GMN": "9005344HB6805458",
            "Url": "https://demo.xtrade.no/masterProduct/helland/2079/summary/"
          },
          {
            "Serial": "10002",
            "Rfid": "300EA4290200090000002712",
            "Name": "Helland Pan High Recliner",
            "GMN": "9005344HB6805458",
            "Url": "https://demo.xtrade.no/masterProduct/helland/2079/summary/"
          },
          {
            "Serial": "10003",
            "Rfid": "300EA4290200090000002713",
            "Name": "Helland Pan High Recliner",
            "GMN": "9005344HB6805458",
            "Url": "https://demo.xtrade.no/masterProduct/helland/2079/summary/"
          }
        ]
      },
      {
        "Gtin": "7090053440376",
        "Values":
        [
          {
            "Serial": "10001",
            "Rfid": "300EA4290200094000002711",
            "Name": "Helland Pan seat cushion Gabriel 6301",
            "GMN": "",
            "Url": "https://demo.xtrade.no/MasterProduct/helland/2067"
          },
          {
            "Serial": "10002",
            "Rfid": "300EA4290200094000002712",
            "Name": "Helland Pan seat cushion Gabriel 6301",
            "GMN": "",
            "Url": "https://demo.xtrade.no/MasterProduct/helland/2067"
          },
          {
            "Serial": "10003",
            "Rfid": "300EA4290200094000002713",
            "Name": "Helland Pan seat cushion Gabriel 6301",
            "GMN": "",
            "Url": "https://demo.xtrade.no/MasterProduct/helland/2067"
          }
        ]
      },
      {
        "Gtin": "7090053440383",
        "Values":
        [
          {
              "Serial": "10001",
              "Rfid": "300EA4290200098000002711",
              "Name": "Gabriel Capture 6301",
              "GMN": "9005344CAP6301A5",
              "Url": "https://demo.xtrade.no/product/gabriel2/673326"
          },
          {
              "Serial": "10002",
              "Rfid": "300EA4290200098000002712",
              "Name": "Gabriel Capture 6301",
              "GMN": "9005344CAP6301A5",
              "Url": "https://demo.xtrade.no/product/gabriel2/673326"
          },
          {
              "Serial": "10003",
              "Rfid": "300EA4290200098000002713",
              "Name": "Gabriel Capture 6301",
              "GMN": "9005344CAP6301A5",
              "Url": "https://demo.xtrade.no/product/gabriel2/673326"
          }
        ]
      }
    ]

  const pageState = ref(0)
  
  const handleButton = async() => {
    window.location = link.value
  }

  const getInfo = async() => {
    for (let index in items) {
      if (gtin.value === items[index]["Gtin"]) {
        console.log(items[index]["Values"])

        for (let serialIndex in items[index]["Values"]) {
          if (items[index]["Values"][serialIndex]["Serial"] === serial.value) {
            name.value = items[index]["Values"][serialIndex].Name
            gmn.value = items[index]["Values"][serialIndex].GMN
            link.value = items[index]["Values"][serialIndex].Url
        
            pageState.value = 1
          }

        }
      }
    }
  }

  watch(() => route.name, async () => {
    if (route.name === "info") {
        await getInfo()
    }
  })

  onMounted(async () => {
    if (site.value === "info") {
        await getInfo()
    }
  })



</script>

<template>
  <q-page>
    <div class=center-screen>
      <div class="q-pa-md row items-start">

        <!-- pageState 0 is for wrong url, error in server or not registered container in room-->
        <div v-if="pageState === 0">
        </div>

        <!-- pageState 1 is for containerInformation -->
        <div v-else-if="pageState === 1">
          <q-card class="bg-primary text-white">
              <q-card-section>
                <div class="information-container">
                <div class="val-info">
                    <q-input outlined style="font-size: 18px;" v-model="name" readonly />
                    <br>
                    <q-input outlined style="font-size: 18px;" v-model="gmn" readonly />
                </div>
                  </div>

              </q-card-section>

              <q-separator dark />

              <q-card-actions align="around">
                <q-btn color="white" text-color="black" style ="width:100%; height: 70px; font-size: 18px;" @click="handleButton">Xeris</q-btn>
              </q-card-actions>
            </q-card>
          </div>
      </div>
    </div>
  </q-page>
</template>

<style>

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 75vh;
}

.information-container {
  display: flex;
  flex-wrap: nowrap;
}

.val-info, .string-info {
  flex: 1, 1, 100px;
  white-space: nowrap;
}

p {
  font-size: 25px;
}

.q-input {
    background-color: white;
    font-size: 25px;
}


</style>