import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Gtin from '../views/Gtin.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // Prod links
  {
    path: '/#/info',
    name: 'info',
    component: Gtin,
  },
  // Localhost links
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router